import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiResponse } from '../../models/commonModel';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpStatusCode } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import * as CryptoJS from 'crypto-js';
import { Observable, catchError, of, tap, Subject, BehaviorSubject, throwError, share } from 'rxjs';
import { AppService } from '../app-service/app.service';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorMessageConstants, TokenConstants } from 'src/app/common/Constants/MessageConstants';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private userImageSource = new BehaviorSubject<string | null>(null);
  userImage$ = this.userImageSource.asObservable();
  private errorMessage: string = "Something went wrong. Please try again after sometime.";
  setAutoHide: boolean = true;
  autoHide: number = 2000;
  private encryptionKey = CryptoJS.enc.Utf8.parse('EA34FF3E-JU84-1974-AW70-BB81D9564426');
  private KEY = CryptoJS.enc.Hex.parse(
    'c604f199ff095b4ced4caf373264e84045f71b384ce39628ee8adca2b27109e8'
  );
  private IV = CryptoJS.enc.Hex.parse('47b5b25d579c0bed815c9166b4f37ee8');
  private salt = CryptoJS.enc.Base64.parse('SXZhbiBNZWR2ZWRldg==');
  private iterations = 100000;
  private CryptoAlgo = CryptoJS.algo.SHA512;
  private logoutSubject = new Subject<void>();
  public logoutAction$ = this.logoutSubject.asObservable();
  public href: any;
  IsError: boolean = false;
  isSystemAlert: boolean = false;
  constructor(private router: Router, private toster: ToastrService, private dialog: MatDialog, private spinner: NgxSpinnerService, private appService: AppService, private http: HttpClient) {
  }

  doGet(apiUrl: String): Observable<ApiResponse> {
    const httpOptions = {
      headers: new HttpHeaders()
    };
    let loginData = localStorage.getItem('authToken');
    if (loginData) {
      httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + loginData);
      httpOptions.headers = httpOptions.headers.set('Access-Control-Allow-Origin', '*');
      httpOptions.headers = httpOptions.headers.set('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, DELETE, PUT');
    }
    const url = `${environment.BaseURL}${apiUrl}`;
    return this.http.get<ApiResponse>(url, httpOptions).pipe(
      tap(() => this.log(`doGet success`)),
      catchError((error: HttpErrorResponse) => {
        this.checkAuthorize(error);
        return throwError(() => error);
      })
    );
  }

  doPost(apiUrl: string, postData: any): Observable<ApiResponse> {
    const httpOptions = {
      headers: new HttpHeaders(
      )
    };
    let loginData = localStorage.getItem('authToken');
    if (loginData) {
      httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + loginData);
      httpOptions.headers = httpOptions.headers.set('Access-Control-Allow-Origin', '*');
      httpOptions.headers = httpOptions.headers.set('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, DELETE, PUT');
    }
    const url = `${environment.BaseURL}${apiUrl}`;
    return this.http.post<ApiResponse>(url, postData, httpOptions).pipe(
      tap(() => this.log(`doGet success`)),
      catchError((error: HttpErrorResponse) => {
        this.checkAuthorize(error);
        return throwError(() => error);
      })
    );
  }

  doPut(apiUrl: string, putData: any): Observable<ApiResponse> {
    const httpOptions = {
      headers: new HttpHeaders(
      )
    };
    let loginData = localStorage.getItem('authToken');
    if (loginData) {
      httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + loginData);
      httpOptions.headers = httpOptions.headers.set('Access-Control-Allow-Origin', '*');
      httpOptions.headers = httpOptions.headers.set('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, DELETE, PUT');
    }
    const url = `${environment.BaseURL}${apiUrl}`;
    return this.http.put<ApiResponse>(url, putData, httpOptions).pipe(
      tap(() => this.log(`doGet success`)),
      catchError((error: HttpErrorResponse) => {
        this.checkAuthorize(error);
        return throwError(() => error);
      })
    );
  }

  doDownloadPost(apiUrl: string, postData: any) {
    const httpOptions = {
      headers: new HttpHeaders()
    };
    let loginData = localStorage.getItem('authToken');
    if (loginData) {
      httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + loginData);
      httpOptions.headers = httpOptions.headers.set('Access-Control-Allow-Methods', '*');
    }
    const url = `${environment.BaseURL}${apiUrl}`;
    return this.http.post(url, postData, { headers: httpOptions.headers, observe: "response", responseType: "blob" });
  }

  downloadFileGet(apiUrl: string): Observable<Blob> {
    const url = `${environment.BaseURL}${apiUrl}`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
    });

    return this.http.get<Blob>(url,
      {
        responseType: 'blob' as 'json',
        headers: headers,
      }).pipe(
        share() // Add the share operator here if needed
      );
  }

  downloadFilePost(apiUrl: string, postData: any): Observable<Blob> {
    const url = `${environment.BaseURL}${apiUrl}`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + localStorage.getItem('authToken'),
    });

    return this.http.post<Blob>(url, postData,
      {
        responseType: 'blob' as 'json',
        headers: headers,
      }).pipe(
        share() // Add the share operator here if needed
      );
  }

  doDelete(apiUrl: String, idtoDelete: any): Observable<ApiResponse> {
    const httpOptions = {
      headers: new HttpHeaders(
      )
    };
    let loginData = localStorage.getItem('authToken');
    if (loginData) {
      httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + loginData);
      httpOptions.headers = httpOptions.headers.set('Access-Control-Allow-Origin', '*');
      httpOptions.headers = httpOptions.headers.set('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, DELETE, PUT');
    }

    const options = {
      headers: httpOptions.headers,
      body: {
        UserId: idtoDelete
      }
    };
    const url = (`${environment.BaseURL}${apiUrl}`);
    return this.http.delete<ApiResponse>(url, httpOptions).pipe(
      tap(() => this.log(`doGet success`)),
      catchError((error: HttpErrorResponse) => {
        this.checkAuthorize(error);
        return throwError(() => error);
      })
    );
  }

  // Check Authorize Role
  checkAuthorize(error) {
    if (error.status == HttpStatusCode.Unauthorized) {
      if (!this.IsError) {
        this.IsError = true
        this.toster.error(TokenConstants.Session_Expired);
      }
      this.spinner.hide();
      this.appService.logout();
      this.dialog.closeAll();
    }
    else if (error.status == HttpStatusCode.Forbidden) {
      if (!this.IsError) {
        this.IsError = true
        this.toster.error(TokenConstants.Session_Expired);
        localStorage.clear();
      }
      this.appService.logout();
      this.spinner.hide();
      this.dialog.closeAll();
    }
    else if (error.status === HttpStatusCode.InternalServerError) {
      this.toster.error(ErrorMessageConstants.Message)
      this.appService.logout();
      this.spinner.hide();
      this.dialog.closeAll();
    }
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {

    return (error: any): Observable<T> => {
      this.checkAuthorize(error);

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a HeroService message with the MessageService */
  private log(message: string) {
    this.IsError = false;
  }

  // Check form value changed or not
  onFormValueChange(status, initalValues, form): boolean {
    if (!status) {
      const initialValue = initalValues;
      let formUnchanged = Object.keys(initialValue).some(key => form[key] != initialValue[key])
      return formUnchanged;
    }
    else {
      return false
    }
  }

  generateCouponCode(): string {
    const length = 6;
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let couponCode = '';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      couponCode += characters.charAt(randomIndex);
    }
    return couponCode;
  }

  setUserImage(imageSrc: string | null) {
    this.userImageSource.next(imageSrc);
  }

  getUserImage(): Observable<string | null> {
    return this.userImage$;
  }
  private formSubmittedSubject = new BehaviorSubject<boolean>(false);
  formSubmitted$ = this.formSubmittedSubject.asObservable();

  setFormSubmitted(value: boolean): void {
    this.formSubmittedSubject.next(value);
  }

  getFormSubmitted(): boolean {
    return this.formSubmittedSubject.value;
  }

  public Encrypt(clearText: string): string {
    let encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(clearText), this.KEY,
      {
        keySize: 128 / 8,
        iv: this.IV,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      });
    return encodeURIComponent(encrypted.toString());
  }

  public Decrypt(cipherText: string): string {
    let decrypted = CryptoJS.AES.decrypt(decodeURIComponent(cipherText), this.KEY, {
      keySize: 128 / 8,
      iv: this.IV,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);

  }

  isZipCodeValid(event: any) {
    let array = event?.split(' ');
    if ((array.length <= 2 && array.length != 0)) {
      if( event.trim() != ''){
        return true
      }
      else{
        return false;
      }
    }
    return false
  }

  getTimeZone() {
    const timezoneOffset = new Date().getTimezoneOffset();
    const offset = Math.abs(timezoneOffset);
    const offsetOperator = timezoneOffset < 0 ? '+' : '-';
    const offsetHours = Math.floor(offset / 60).toString().padStart(2, '0');
    const offsetMinutes = Math.floor(offset % 60).toString().padStart(2, '0');
    if(offsetHours == "00" && offsetMinutes == "00"){
      return '+00:00';
    }
    return `${offsetOperator}${offsetHours}:${offsetMinutes}`;
  }

  makeDecimalRound(number:any)
  {
    let decimalPart = number - Math.floor(number);
    let decimalString = decimalPart.toFixed(4); // Convert decimal part to string with 2 decimal places
    let decimalDigits = parseInt(decimalString.slice(3)); // Get the digits after the decimal point

    if (decimalDigits > 5) {
        return Math.ceil(number * 100) / 100;
    } else {
        return Math.floor(number * 100) / 100;
    }
  }

  downloadPdf(url: string): Observable<Blob> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/pdf' });
    return this.http.get(url, { headers, responseType: 'blob' });
  }

  downloadAndSavePdf(url: string, filename: string): void {
    this.downloadPdf(url).subscribe(response => {
      const blob = new Blob([response], { type: 'application/pdf' });
      saveAs(blob, filename);
    }, error => {
      console.error('Download failed', error);
    });
  }


}
