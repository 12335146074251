<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="utf-8">
    <meta name="viewport"
        content="width=device-width, height=device-height, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0">
    <title>Untitled</title>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.3/css/bootstrap.min.css">
</head>

<body>
    <div class="register-photo">
        <div class="form-container">
            <div class="image-holder"></div>
            <form [formGroup]="RegistrationForm" (ngSubmit)="RegisterUser()" novalidate>
                <h2 class="text-center"><strong>Create</strong> an account.</h2>
                <div class="form-group">
                    <input class="form-control" name="FirstName" formControlName="FirstName" placeholder="First Name">
                    <span class="text-danger"
                        *ngIf="submitted && GetRegistrationControls['FirstName'].errors?.['required']">
                        First name is required.</span>
                </div>
                <div class="form-group">
                    <input class="form-control" name="LastName" formControlName="LastName" placeholder="Last Name">
                    <span class="text-danger"
                        *ngIf="submitted && GetRegistrationControls['LastName'].errors?.['required']">
                        Last name is required.</span>
                </div>
                <div class="form-group">
                    <input class="form-control" name="email" formControlName="EmailAddress" placeholder="Email Address">
                    <span class="text-danger"
                        *ngIf="submitted && GetRegistrationControls['EmailAddress'].errors?.['required']">
                        Email address is required.
                    </span>
                    <br>
                    <span class="text-danger"
                        *ngIf="submitted && GetRegistrationControls['EmailAddress'].errors?.['pattern']">Email is
                        invalid</span>
                </div>
                <div class="form-group">
                    <input class="form-control" type="password" name="password" formControlName="Password"
                        placeholder="Password">
                    <span class="text-danger"
                        *ngIf="submitted && GetRegistrationControls['Password'].errors?.['required']">
                        Password is required.
                    </span>
                </div>
                <div class="form-group">
                    <input class="form-control" type="password" name="password-repeat" formControlName="ConfirmPassword"
                        placeholder="Confirm Password">
                    <span class="text-danger"
                        *ngIf="submitted && GetRegistrationControls['ConfirmPassword'].errors?.['required']">
                        Confirm password is required.
                    </span>
                    <span class="text-danger" *ngIf="submitted && hasPasswordMismatchError()">
                        Password does not match.
                    </span>
                </div>
                <div class="form-group"><button class="btn btn-primary btn-block" type="submit">Sign Up</button></div><a
                    href="#" class="already">You already have an account? Login here.</a>
            </form>
        </div>
    </div>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.2.1/jquery.min.js"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.1.3/js/bootstrap.bundle.min.js"></script>
</body>

</html>