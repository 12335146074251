import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ApiUrlHelper } from 'src/app/common/api-url-helper';
import { CommonErrorMessages } from 'src/app/constants/ErrorMessages';
import { ForgotPassLabelConstants } from 'src/app/constants/LabelConstants';
import { CommonService } from 'src/app/services/common/common.service';
import { SharedService } from 'src/app/services/shared-service/shared.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {

  // Common Properties
  EmailForm: FormGroup;
  IsEmailSend: boolean = false;
  submitted: boolean = false;
  ErrorMessages: any;
  LabelConstants: any;

  constructor(
    private spinner: NgxSpinnerService,
    private api: ApiUrlHelper,
    private toastr: ToastrService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private sharedService: SharedService
  ) { }

  ngOnInit(): void {
    this.sharedService.setData('Forgot Password');
    this.EmailForm = this.fb.group({
      UserEmail: [
        '',
        [
          Validators.required,
          Validators.email,
          Validators.pattern(
            /^(?=.{1,30}@)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
          ),
        ],
      ],
    });
    this.ErrorMessages = CommonErrorMessages;
    this.LabelConstants = ForgotPassLabelConstants;
  }

  get EmailFormValidations() {
    return this.EmailForm.controls;
  }

  submitForgotPassForm() {
    this.submitted = true;
    if (this.EmailForm.valid) {
      this.submitted = false;
      this.spinner.show();
      let api = this.api.apiUrl.ForgotPass.ForgotSendEmail;
      let ForgotPassRequestModel = {
        EmailId: this.EmailForm.value.UserEmail?.trim(),
      };
      this.commonService.doPost(api, ForgotPassRequestModel).pipe().subscribe({
        next: (response) => {
          if (response.success) {
            this.EmailForm.reset();
            this.submitted = false;
            this.spinner.hide();
            this.toastr.success(response.message);
            this.IsEmailSend = true;
          } else {
            this.spinner.hide();
            this.toastr.error(response.message);
          }
        },
        error: (err) => {
          this.spinner.hide();
          console.log(err);
        }
      });
    }
  }
}