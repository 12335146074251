import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UnderMainatanceLabelConstant } from 'src/app/constants/LabelConstants';
import { SharedService } from 'src/app/services/shared-service/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-under-mainatance',
  templateUrl: './under-mainatance.component.html',
  styleUrls: ['./under-mainatance.component.scss']
})
export class UnderMainatanceComponent {

  LabelConstant: any;

  constructor(private sharedService: SharedService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.LabelConstant = UnderMainatanceLabelConstant;
    this.sharedService.setData('Under Maintenance');
    if(environment.isUnderMaintance){
      this.router.navigate([''])
    }
  }
}